import { generateUniqueIdentifier } from "../plugins/utils";

export const xbrlDataStore = {
    namespaced: true,
    state: {
        reportTags: {},
        displaySideMenu: false,
        taxonomies: {
            loading: false
        },
        reportInfo: {},
        editorSelection: null,
        selectionAttributes: {},
        monetaryUnits: [],
        units: {},
        complexUnits: {}
    },
    getters: {
        reportTaxonomy(state) {
            return state.taxonomies;
        },
        getTagById: (state) => (id) => {
            return state.reportTags[id];
        },
        getColumnAspectByTagIdAndIndex: (state) => (tagId, index) => {
            if (state.reportTags && state.reportTags[tagId]){
                return state.reportTags[tagId].columns[index];
            }
        },
        getConceptDataByXbrlId: (state) => (xbrlId) => {
            if (state.taxonomies.concepts){
                return state.taxonomies.concepts[xbrlId];
            }
        },
        getColumnDataByTableXbrlIdAndIndex: (state) => (xbrlId,columnIndex) => {
            if(state.reportTags) {
                let tag = state.reportTags[xbrlId];
                if (tag != null && tag != undefined && tag.columns) {
                    return tag.columns[columnIndex];
                }
            }
        },
        getXbrlIdsByConceptId: (state) => (conceptId) => {            
            let idList = [];
            for (let [key, value] of Object.entries(state.reportTags)){
                if (value.type !== "table") {
                    if (value.id === conceptId) {
                        idList.push(key);
                    }
                }else{
                    if (value.rows) {
                        for (let [rowKey, rowValue] of Object.entries(value.rows)){
                            if (rowValue.id === conceptId) idList.push(rowKey);
                        }
                    }
                }
            }
            return idList;
        },
        getTagContinuations: (state) => (tagId) => {
            let tag = state.reportTags[tagId];
            let continuations = {marks: [], blocks: []};
            if (tag !== null && tag !== undefined && tag.attrs && tag.attrs.continue !== true){
                for (let [key, value] of Object.entries(state.reportTags)){
                    if (value.id === tag.id) {
                        if (value.attrs.type === "string") continuations.marks.push(key);
                        else if (value.attrs.type === "block") continuations.blocks.push(key);
                    }
                }
            }
            return continuations;
        },
        getReportMonetaryUnits(state) {
            return state.monetaryUnits;
        }
    },
    mutations: {
        setReportTags(state,reportTags) {
            state.reportTags = reportTags;
        },
        setTagById(state, {id, tag, attrs}) {
            if(tag?.type != "table") {
                state.reportTags[id] = {...tag,attrs};
            } else {
                state.reportTags[id] = tag;
            }
        },
        setRowTagByIdAndTableId(state, {id, tag, tableId, attrs}){
            let newReportTags =  Object.assign({}, state.reportTags);
            newReportTags[tableId].rows[id] = {...tag,attrs};
            state.reportTags = newReportTags;
        },
        setColumnAspectByTagIdAndIndex(state, {tagId, index, aspect}) {
            let newReportTags =  Object.assign({}, state.reportTags);
            newReportTags[tagId].columns[index] = aspect;
            state.reportTags = newReportTags;
        },
        addMarkTag(state, {xbrlData, xbrlId}) {
            let newReportTags =  Object.assign({}, state.reportTags);
            newReportTags[xbrlId] = xbrlData;
            state.reportTags = newReportTags;
        },
        openSideMenu(state) {
            state.displaySideMenu = true;
        },
        closeSideMenu(state) {
            state.displaySideMenu = false;
        },
        setTaxonomies(state,taxonomies) {
            state.taxonomies = taxonomies;
        },
        setReportBaseInfo(state,reportInfo) {
            state.reportInfo = reportInfo;
        },
        setEditorSelection(state, selection) {
            state.editorSelection = selection;
        },
        setSelectionAttributes(state,attributes) {
            state.selectionAttributes = attributes;
        },
        removeMarkById(state, {tagId}) {
            let newReportTags =  Object.assign({}, state.reportTags);
            delete newReportTags[tagId];
            state.reportTags = newReportTags;
            
        },
        removeRowTagByIdAndTableId(state, {tagId,tableId}) {
            let newReportTags =  Object.assign({}, state.reportTags);
            delete newReportTags[tableId].rows[tagId];
            state.reportTags = newReportTags;
             
        },
        editTagById(state,{id, tag, attrs})  {
            let newReportTags =  Object.assign({}, state.reportTags);
            newReportTags[id] = {...tag,attrs};
            state.reportTags = newReportTags;
        },
        setMonetaryUnits(state, units) {
            state.monetaryUnits = units;
        },
        setXbrlUnits(state, units) {
            state.units = units;
        }
    },
    actions: {
        openSideMenu ({commit}) {
            commit('openSideMenu');
        },
        closeSideMenu ({commit}) {
            commit('closeSideMenu');
        },
        addTag ({commit},{id,editedColumn,nodeType}) {
            commit('addNewXbrlTag',{id,editedColumn,nodeType})
        },
        editTag ({commit},{xbrlId,editedColumn}) {
            commit('editXbrlTags',{xbrlId,editedColumn});
        },
        addNewTag({commit}, {tag, stringForHash, attrs}) {
            return new Promise((resolve, reject) => {
                generateUniqueIdentifier(stringForHash).then(id => {
                    commit('setTagById',{id,tag,attrs});
                    resolve(id);
                }).catch(err => {
                    console.log(err);
                    reject(false);
                });
            });
        },
        addNewRowTag({commit, dispatch}, {tag, stringForHash, tableId, attrs}) {
            return new Promise((resolve, reject) => {
                if (!tableId){
                    dispatch("addNewTag", {
                        tag: {
                            type: 'table',
                            columns: {},
                            rows: {},
                            tableDefaults: {}
                    }, stringForHash}).then(tabId => {
                        generateUniqueIdentifier(stringForHash+tabId).then(id => {
                            commit('setRowTagByIdAndTableId',{id,tag, tableId: tabId, attrs});
                            resolve({tagId: id, tableId: tabId});
                        }).catch(err => {
                            console.log(err);
                            reject(false);
                        });
                    });
                }else{
                    generateUniqueIdentifier(stringForHash+tableId).then(id => {
                        commit('setRowTagByIdAndTableId',{id,tag, tableId,attrs});
                        resolve({tagId: id, tableId});
                    }).catch(err => {
                        console.log(err);
                        reject(false);
                    });
                }
            });
        },
        removeTagById({commit}, {tagId}) {
            commit('removeMarkById', {tagId});
        },
        editTagByMarkId({commit}, {id, tag, attrs}) {
            commit('editTagById',{id,tag,attrs});
        },
        getXbrlUnits({commit}, vm){
            vm.$apiHttp({ url: `/xbrl/units`}).then(response => {
                if(response && response.data) {
                    commit('setMonetaryUnits', response.data.monetary);
                    delete response.data.monetary;
                    commit('setXbrlUnits', response.data);
                }
            });
        },
    },
}