const fontSelectItems = Object.freeze([
    { 
        text: "Arial",
        value: "Arial, Helvetica, sans-serif",
    },
    { 
        text: "Times New Roman",
        value: '"Times New Roman", Times, serif',
    },
    { 
        text: "Georgia",
        value: '"Georgia", Georgia, serif',
    },
    { 
        text: "Roboto",
        value: "'Roboto', sans-serif",
    },
    { 
        text: "Lobster",
        value: "'Lobster', cursive",
    }

    
]);

export default fontSelectItems;