<template>
    <div>
        <v-dialog
            :value="value"
            @input="$emit('input', $event.target.value)"
            persistent
            dismissible
            width="50%"
        >
            <v-form>
                <v-card>
                    <v-card-title>Change password</v-card-title>
                    <v-card-text>
                        <v-row>
                            <v-col>
                                <v-text-field
                                    label="Existing password"
                                    type="password"
                                    v-model="existing"
                                    :error-messages="existingErrors"
                                >
                                </v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-text-field
                                    label="New password"
                                    type="password"
                                    v-model="newp"
                                    :error-messages="newpErrors"
                                >
                                </v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-text-field
                                    label="Repeat new password"
                                    type="password"
                                    v-model="repeat"
                                    :error-messages="repeatErrors"
                                >
                                </v-text-field>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="secondary" text @click="close"
                            >Cancel</v-btn
                        >
                        <v-btn
                            color="success"
                            :loading="busy"
                            text
                            @click="submit"
                            >Save</v-btn
                        >
                    </v-card-actions>
                </v-card>
            </v-form>
        </v-dialog>
    </div>
</template>

<script>

import {validationMixin} from "vuelidate";
import {required, sameAs} from "vuelidate/lib/validators";
export default {
    name:"change-password",
    props:{
        value: {
            type: Boolean,
            default: false,
        }
    },
    mixins:[validationMixin],
    validations:{
        existing: {required},
        newp:{
            required,
            sameAsRepeat: sameAs("repeat")
            },
        repeat:{
            required,
            sameAsNew: sameAs("newp")
        }
    },
    data(){
        return{
            busy: false,
            existing: "",
            newp: "",
            repeat: "",
        }
    },
    methods:{
        close(){
            this.existing = "";
            this.newp = "",
            this.repeat = ""
            this.$v.$reset();
            this.$emit('input', false);
        },
        submit(ev){
            ev.preventDefault();
            
            this.$v.$touch();
            if (this.$v.$invalid){
                return false;
            }

            let vm = this;
            this.busy = true;
            this.$apiHttp({
                method: "put",
                url:"/users/change_password",
                data:{
                    existing: this.existing,
                    newp: this.newp
                }
            })
            .then(()=>{
                vm.close();
            })
            .finally(function(){
                vm.busy = false;
            });
        }
    },
    computed:{
        existingErrors(){
            let errors = [];
            if (!this.$v.existing.$dirty){
                return errors;
            }
            !this.$v.existing.required && errors.push(this.$store.state.validationErrors.required);
            
            return errors;
        },
        newpErrors(){
            let errors = [];
            if (!this.$v.newp.$dirty){
                return errors;
            }
            !this.$v.newp.required && errors.push(this.$store.state.validationErrors.required);
            !this.$v.newp.sameAsRepeat && errors.push("The passwords do not match");
            return errors;
        },
        repeatErrors(){
            let errors = [];
            if (!this.$v.newp.$dirty){
                return errors;
            }
            !this.$v.repeat.required && errors.push(this.$store.state.validationErrors.required);
            !this.$v.repeat.sameAsNew && errors.push("The passwords do not match");
            
            return errors;
        },
    }
};
</script>