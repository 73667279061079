<template>
    <validation-observer ref="form" v-slot="{ handleSubmit}">
        <v-card>
            <v-card-title>
                <h3 v-if="unit == null">Add complex unit</h3>
                <h3 v-else>Edit complex unit <span class='info--text'>{{unit.name}}</span></h3>
            </v-card-title>

            <v-card-text>
                <v-row class="mb-2">
                    <v-col cols="6">
                        <uv-text-field label="Name" v-model="localUnit.name" name="Name" rules="required|alpha_dash"></uv-text-field>
                    </v-col>
                </v-row>
                <v-card outlined>
                    <v-card-title class="text-overline">Numerator</v-card-title>
                    <v-card-text>
                        
                        <v-row>
                            <v-col>
                                <uv-select
                                    label="Type"
                                    :items="types"
                                    v-model="numerator.type"
                                    @change="numerator.value = null"
                                >
                                </uv-select>
                            </v-col>
                            <v-col>
                                <uv-autocomplete v-if="numerator.type == 'Monetary'"
                                    v-model="numerator.value"
                                    label="Monetary Unit"
                                    :items="monetaryUnits"
                                    item-value="id"
                                    item-text="name"
                                    clearable eager
                                    :filter="findUnit"
                                    :return-object="true"
                                    rules="required"
                                    @change="(value) => onChange(value, 'numerator')"
                                    >
                                    
                                    <template #item="{item}">
                                        <v-list-item-content>
                                            <v-list-item-title>{{item.id}}</v-list-item-title>
                                            <v-list-item-subtitle>{{item.name}}</v-list-item-subtitle>
                                        </v-list-item-content>
                                        <v-list-item-action>
                                            <uv-button color="info" icon :title="item.description" @click.stop="()=>false">
                                                <v-icon>mdi-information-variant</v-icon>
                                            </uv-button>
                                        </v-list-item-action>
                                    </template>
                                    <template #selection="{item}">
                                        {{item.id}}
                                    </template>
                                </uv-autocomplete>

                                <uv-autocomplete v-else
                                    v-model="numerator.value"
                                    label="Unit"
                                    :items="unitsItems"
                                    item-value="id"
                                    item-text="name"
                                    eager clearable
                                    :filter="findUnit"
                                    :return-object="true"
                                    rules="required"
                                    @change="(value) => onChange(value, 'numerator')"
                                    >
                                    
                                    <template #item="{item}">
                                        <v-list-item-content>
                                            <v-list-item-title>{{item.id}}</v-list-item-title>
                                            <v-list-item-subtitle>{{item.name}}</v-list-item-subtitle>
                                        </v-list-item-content>
                                        <v-list-item-action>
                                            <uv-button color="info" icon :title="item.description" @click.stop="()=>false">
                                                <v-icon>mdi-information-variant</v-icon>
                                            </uv-button>
                                        </v-list-item-action>
                                    </template>
                                    <template #selection="{item}">
                                        {{item.id}}
                                    </template>
                                </uv-autocomplete>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>

                <v-card outlined class="mt-1">
                    <v-card-title class="text-overline">Denominator</v-card-title>
                    <v-card-text>
                        <v-row>
                            <v-col>
                                <uv-select
                                    label="Type"
                                    :items="types"
                                    v-model="denominator.type"
                                    @change="denominator.value = null"
                                >
                                </uv-select>
                            </v-col>
                            <v-col>
                                <uv-autocomplete v-if="denominator.type == 'Monetary'"
                                    v-model="denominator.value"
                                    label="Monetary Unit"
                                    :items="monetaryUnits"
                                    item-value="id"
                                    item-text="name"
                                    clearable eager
                                    :filter="findUnit"
                                    :return-object="true"
                                    rules="required"
                                    @change="(value) => onChange(value, 'denominator')"
                                    >
                                    
                                    <template #item="{item}">
                                        <v-list-item-content>
                                            <v-list-item-title>{{item.id}}</v-list-item-title>
                                            <v-list-item-subtitle>{{item.name}}</v-list-item-subtitle>
                                        </v-list-item-content>
                                        <v-list-item-action>
                                            <uv-button color="info" icon :title="item.description" @click.stop="()=>false">
                                                <v-icon>mdi-information-variant</v-icon>
                                            </uv-button>
                                        </v-list-item-action>
                                    </template>
                                    <template #selection="{item}">
                                        {{item.id}}
                                    </template>
                                </uv-autocomplete>

                                <uv-autocomplete v-else
                                    v-model="denominator.value"
                                    label="Unit"
                                    :items="unitsItems"
                                    item-value="id"
                                    item-text="name"
                                    eager clearable
                                    :filter="findUnit"
                                    :return-object="true"
                                    rules="required"
                                    @change="(value) => onChange(value, 'denominator')"
                                    >
                                    
                                    <template #item="{item}">
                                        <v-list-item-content>
                                            <v-list-item-title>{{item.id}}</v-list-item-title>
                                            <v-list-item-subtitle>{{item.name}}</v-list-item-subtitle>
                                        </v-list-item-content>
                                        <v-list-item-action>
                                            <uv-button color="info" icon :title="item.description" @click.stop="()=>false">
                                                <v-icon>mdi-information-variant</v-icon>
                                            </uv-button>
                                        </v-list-item-action>
                                    </template>
                                    <template #selection="{item}">
                                        {{item.id}}
                                    </template>
                                </uv-autocomplete>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-card-text>

            <v-card-actions>
                <v-spacer></v-spacer>
                <uv-button text color="secondary" @click="$emit('cancel'); reset()">Cancel</uv-button>
                <uv-button text color="success" @click="handleSubmit(onSubmit)">Save</uv-button>
            </v-card-actions>
        </v-card>
        <uv-confirm-dialog ref="newNamespaceConfirmDialog">
            <span class="font-weight-bold">{{newNamespaceConfirmDialogText}}</span>
        </uv-confirm-dialog>
        <v-dialog :value="displayNamespaceDialog" max-width="500px">
            <add-namspace-form ref="form" 
                :newUri="newNamespaceUri" 
                @close="displayNamespaceDialog = false;"
                @done="displayNamespaceDialog = false;"
                :report="report">
            </add-namspace-form>
        </v-dialog>
    </validation-observer>
</template>

<script>
import {mapState, mapMutations} from "vuex";
import AddNamspaceFormVue from '../../components/AddNamspaceForm.vue';
import { default as UvConfirmDialog } from "../../components/ConfirmDialog.vue";
export default {
    name: "ComplexUnitForm",
    props: {
        unit:{
            type: Object,
            required: false,
            default: null
        },
        report:{
            type: Object,
            required: true
        }
    },
    created(){
        this.unitChanged(this.unit)
    },
    data(){
        return{
            types: ["Monetary", "Unit"],
            numerator: {},
            denominator: {},
            localUnit: {},
            namespaces: {
                xbrli: {name: 'xbrli', url: "http://www.xbrl.org/2003/instance"},
                iso4217: {name: 'iso4217', url: "http://www.xbrl.org/2003/iso4217"}
            },
            newNamespaceConfirmDialogText: "Namespace of unit is not saved in the report. \nDo you wish to add it ?",
            displayNamespaceDialog: false,
            newNamespaceUri: ""
        }
    },
    components: {
            UvConfirmDialog,
            "add-namspace-form" : AddNamspaceFormVue,
    },
    computed: {
        ...mapState({
            'monetaryUnits': state => state.xbrlData.monetaryUnits,
            'units': state => state.xbrlData.units
        }),
        unitsItems: function(){
            let units = [];
            const storeUnits = this.units;
            for (const header of Object.keys(storeUnits)){
                units.push({header: header.replace(header[0], header[0].toUpperCase())})
                units = units.concat(storeUnits[header]);
                units.push({divider: true});
            }

            return units;
        }
    },
    watch: {
        'unit': function(unit){
            this.unitChanged(unit)
        }
    },
    methods:{
        ...mapMutations(['setGlobalMessage']),
        onChange(item, side){
            if (!item){
                return
            }
            const ns = this.findNsByUrl(item.ns)
            if (!ns){
                this.setGlobalMessage({
                    message: `Namespace ${item.ns} is not defined in report info`,
                    severity: "warning"
                });
                this.$refs.newNamespaceConfirmDialog.open().then(confirm => {
                    if (confirm) {
                        this.newNamespaceUri = item.ns;
                        this.displayNamespaceDialog = true;
                    }
                });
                this.localUnit[side] = null
                this[side].value = null
                return false
            }

            if(ns.name == 'iso4217'){
                this.localUnit[side] = `${item.id}`    
            }else{
                this.localUnit[side] = `${ns.name}:${item.id}`
            }
        },
        findUnit(item, queryText) {
            const searchText = queryText.toLowerCase();
            return item.name?.toLowerCase().indexOf(searchText) > -1 || item.id?.toLowerCase().indexOf(searchText) > -1;
        },
        findNsByName(name){
            return this.namespaces[name] ?? this.report.namespaces.find(ns=>ns.name == name)
        },
        findNsByUrl(url){
            return Object.values(this.namespaces).find((ns) => ns.url == url) ?? this.report.namespaces.find((ns) => ns.url == url)
        },
        onSubmit(){
            this.$emit("ok", this.localUnit)
            console.log(this.localUnit);
        },
        unitChanged(unit){
            if (unit == null){
                this.reset();
                return;
            }
            this.localUnit = JSON.parse(JSON.stringify(unit));
            const sides = {
                numerator: {
                    value: this.localUnit.numerator,
                    selectorItem: this.numerator
                },
                denominator: {
                    value: this.localUnit.denominator,
                    selectorItem: this.denominator
                }
            }
            for (const side of Object.values(sides)){
                let valueArray = side.value.split(":")
                if (valueArray.length == 1){
                    side.selectorItem.type = "Monetary"
                    side.selectorItem.value = this.monetaryUnits.find((unit) => unit.id == valueArray[0])
                    if ([undefined, null].includes(side.selectorItem.value)){
                        this.setGlobalMessage({
                            message: `Monetary unit ${side.value} does not exist`,
                            severity: "warning"
                        })
                        return
                    }
                }else{
                    let unitsItems = null;
                    if (valueArray[0] == 'iso4217'){
                        side.selectorItem.type = "Monetary"
                        unitsItems = this.monetaryUnits
                    }else{
                        side.selectorItem.type = "Unit"
                        unitsItems = this.unitsItems
                    }
                    
                    //this.numerator.value = valueArray[1]
                    let unitNs = this.findNsByName(valueArray[0])
                    if ([undefined, null].includes(unitNs)){
                        this.setGlobalMessage({
                            message: `Namespace ${valueArray[0]} is not defined in report info`,
                            severity: "warning"
                        })
                        return
                    }
                    side.selectorItem.value = unitsItems.find(u => unitNs?.url == u.ns && u.id == valueArray[1])
                    

                    if ([undefined, null].includes(side.selectorItem.value)){
                        this.setGlobalMessage({
                            message: `Unit ${side.value} does not exist`,
                            severity: "warning"
                        })
                        return
                    }
                }
            }
        },
        reset(){
            this.localUnit = {
                numerator: "",
                denominator: "",
                name: ""
            }
            this.numerator = {
                type: "Monetary",
                value: null,
            }
            this.denominator = {
                type: "Unit",
                value: null
            }
        }
    }

}
</script>